import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"

const TransformingPage = ({ data }) => {
	const podcasts = data.allSanityPodcast.edges.filter(item => item.node.categories[0].title !== "NARM Podcasts")
	console.log(podcasts)
  return (
		<Layout>
			<SEO
				title="Transforming Trauma Podcast"
				description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
			/>
			<div className="page-headline">
				<div className="container">
					<div className="section-heading text-center">
						<h5 className="font-weight-bold text-uppercase text-orange flair mb-0">
							A podcast brought to you by the NARM® Training Institute
						</h5>
						<h1>Transforming Trauma</h1>
					</div>
				</div>
			</div>
			<div className="page-content">
				<div className="container">
					<div className="row">
						<div className="col-md-10 mx-auto">
							<div className="row justify-content-between align-items-start">
								<div className="col-md-8 order-2 order-md-1">
									<p>
										In a modern world beset by Complex Trauma and a legacy of
										suffering, conflict and disconnection, healing trauma can
										serve as a vehicle for personal and social transformation.
										Transforming Trauma is a podcast about thriving after trauma.
										Throughout our episodes, we will explore the NeuroAffective
										Relational Model (NARM), a revolutionary healing approach for
										healing Complex Trauma (C-PTSD) and restoring connection to
										self and others. Whether you are a healthcare professional, an
										educator, a public policy maker, a trauma survivor, or someone
										interested in personal healing and social justice,
										Transforming Trauma will provide you with a map for increased
										resiliency, greater health outcomes, healthier relationships,
										personal growth and social change. We invite you to listen
										along as we explore how freedom from Complex Trauma is
										possible.
									</p>
								</div>
								<div className="col-md-4 order-1 order-md-2 pb-5 pb-md-0">
									<img
										className="img-fluid"
										src="/assets/img/Transforming-Trauma-Cover-350x350.png"
										alt="Transforming Trauma"
									/>
								</div>
							</div>
							<h3 className="mb-4">
								Subscribe for All Episodes on your Favorite Service
							</h3>
							<div className="row align-items-center">
								<div className="col-md-4 px-4 mb-4">
									<a
										href="https://podcasts.apple.com/us/podcast/transforming-trauma/id1496190024"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img className="img-fluid" src="/assets/img/apple-podcasts.png" alt="apple podcast"/>
									</a>
								</div>
								<div className="col-md-4 px-4 mb-4">
									<a
										href="https://open.spotify.com/show/3bdIUoZPpObByi39pn96sN?si=5911RpXmQgqEyY0OqBksPA"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img className="img-fluid" src="/assets/img/spotify.png" alt="spotify"/>
									</a>
								</div>
								<div className="col-md-4 px-4 mb-4">
									<a
										href="https://www.stitcher.com/podcast/transforming-trauma"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img className="img-fluid" src="/assets/img/stitcher.png" alt="stitcher"/>
									</a>
								</div>
							</div>
							<h3>Healing Complex Trauma</h3>
							<p>
								Complex Trauma is a worldwide health concern. C-PTSD has been
								linked to chronic health problems, including a number of medical
								and mental health disorders. Additionally, C-PTSD has been linked
								to pervasive social problems, such as child abuse and neglect,
								teen pregnancy, substance abuse and addiction, domestic and
								community violence, and systemic oppression.
							</p>
							<p>
								Our understanding of the far-reaching effects of unresolved
								Complex Trauma is supported by the ACEs Study (Adverse Childhood
								Experiences), giving us a deeper appreciation for the long-term
								impact of attachment, relational and developmental trauma on both
								health and social well-being.
							</p>
							<p>
								At the NARM Training Institute, we are at the forefront of the
								Trauma-Informed Care movement, providing an effective approach for
								addressing the impact of unresolved Complex Trauma on individuals,
								couples, families and communities.
							</p>
							<p>
								Transforming Trauma provides interviews with NARM Therapists on
								how NARM addresses the legacy of childhood, cultural and
								intergenerational trauma. These leaders in the Trauma-Informed
								Care movement will guide listeners through the diverse ways NARM
								is applied in actualizing Post-Traumatic Growth. Their shared
								message is clear: Freedom from Complex Trauma is possible.
							</p>
							<p>
								Thank you for joining us on this journey to heal Complex Trauma.
							</p>
							<h3>Explore Episodes of Transforming Trauma</h3>
							<div className="row mt-5">
								{podcasts.map(({ node }) => (
									<div className="col-12" key={node.id}>
										<div className="row">
											<div className="col-md-4 mb-4">
												<img
													className="img-fluid"
													src={node.mainImage.asset.fluid.src}
												/>
											</div>
											<div className="col-md-8 mb-4">
												<iframe
													title={node.title}
													src={node.podcastUrl}
													className="border"
													height={90}
													width="100%"
													scrolling="no"
													allowFullScreen
													webkitallowfullscreen="true"
													mozallowfullscreen="true"
													oallowfullscreen="true"
													msallowfullscreen="true"
												/>
												<h5>
													<Link to={node.slug.current}>{node.title}</Link>
												</h5>
												<p>{node.excerpt}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)

}

export default TransformingPage

export const transformingPageQuery = graphql`
	query transformingPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
		allSanityPodcast(sort: { order: ASC, fields: publishedAt }) {
			edges {
				node {
					id
					title
					subtitle
					podcastUrl
					slug {
						current
					}
					categories {
						title
					}
					excerpt
					host {
						id
						name
						image {
							asset {
								fixed(width: 50, height: 50) {
									src
								}
							}
						}
					}
					mainImage {
						asset {
							fluid {
								src
							}
						}
					}
				}
			}
		}
	}
`
